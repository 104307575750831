import React from "react";
import { useContentful } from "react-contentful";
import { Helmet } from "react-helmet";
import Home from "./Home";
import WhoWeAre from "./WhoWeAre";
import Portrait from "./Portrait";
import WhatWeDo from "./WhatWeDo";
import { isMobile } from "react-device-detect";
import WhatWeDoMobile from "./WhatWeDoMobile";
import Technology from "./Technology";
import Partners from "./Partners";

const DefaultScreen = ({
  cmsDocumentID,
  isCurrent,
  locale,
  btnPrev,
  btnNext,
  handleGoHome,
  index,
  realIndex,
}) => {
  const { data, error, fetched, loading } = useContentful({
    locale: locale,
    contentType: "page",
    query: {
      "sys.id": `${cmsDocumentID}`,
    },
  });

  if (loading || !fetched || error) {
    return null;
  }

  if (error) {
    console.error(error);
    return null;
  }

  if (!data) {
    return <p>Page does not exist.</p>;
  }

  if (fetched && data) {
    return (
      <>
        <Helmet>
          {isCurrent ? (
            <title>{data.items[0] ? data.items[0].fields.title : ""}</title>
          ) : null}
        </Helmet>
        {/* <>
                <pre
                    style={{
                        display:'none'
                    }}
                >
                    IsCurrent : { JSON.stringify( isCurrent , null, 2 )}
                    <br />
                    Position : { JSON.stringify( data.items[0].fields.position , null, 2 )}
                    <br />
                    Index : { JSON.stringify( index , null, 2 )}
                    <br />
                    realIndex : { JSON.stringify( realIndex , null, 2 )}
                </pre>
                </> */}

        {cmsDocumentID === "6uZMaGY1C9jilz92eVMZ3" ||
        cmsDocumentID === "5u9dRvXrP2dfah95txX8C4" ? (
          <WhoWeAre
            btnPrev={btnPrev}
            btnNext={btnNext}
            data={data.items[0]}
            handleGoHome={handleGoHome}
          />
        ) : null}

        {cmsDocumentID === "6ZGjFSpEhhQ84rUkpG8lLO" ? (
          <Home
            btnPrev={btnPrev}
            btnNext={btnNext}
            data={data.items[0]}
            isCurrent={isCurrent}
            handleGoHome={handleGoHome}
          />
        ) : null}

        {cmsDocumentID === "2NpSDkUKZKYHc2u8adcKH1" ||
        cmsDocumentID === "57nn5tmiT3qhUuo0K8DSEs" ||
        cmsDocumentID === "2Gnguu5RPL8b42i4o482S3" ||
        cmsDocumentID === "47AZw7P3uscTXr1QB0yYds" ||
        cmsDocumentID === "4GnAFwaqv0qi0wwLdV22IT" ? (
          <Portrait
            btnPrev={btnPrev}
            btnNext={btnNext}
            data={data.items[0]}
            handleGoHome={handleGoHome}
            isCurrent={isCurrent}
          />
        ) : null}

        {cmsDocumentID === "6miggK63dHItgXBeRx0VhN" ? (
          <Technology
            btnPrev={btnPrev}
            btnNext={btnNext}
            data={data.items[0]}
            handleGoHome={handleGoHome}
          />
        ) : null}

        {cmsDocumentID === "7F8vxk7rp9wSz52385fpLv" ? (
          <Partners
            btnPrev={btnPrev}
            btnNext={btnNext}
            data={data.items[0]}
            handleGoHome={handleGoHome}
          />
        ) : null}

        {cmsDocumentID === "5fbkQ6GR3DL3VW1Hdx7SwG" ? (
          isMobile ? (
            <WhatWeDoMobile
              btnPrev={btnPrev}
              btnNext={btnNext}
              data={data.items[0]}
              handleGoHome={handleGoHome}
            />
          ) : (
            <WhatWeDo
              btnPrev={btnPrev}
              btnNext={btnNext}
              data={data.items[0]}
              handleGoHome={handleGoHome}
            />
          )
        ) : null}
      </>
    );
  }
};

export default React.memo(DefaultScreen);
