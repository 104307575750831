import { Box, Flex, Link, Text } from '@chakra-ui/layout'
import React from 'react'

const AppointmentButton = (props) => {
    return (
        <Box
            {...props}

            border='solid 1px'
            borderColor='brand.blue'
            transform='translate3d(4px, 4px, 0)'
            cursor='pointer'
            role='group'
            _hover={{
                bgColor: 'brand.blue'
            }}
        >
            <Box
                bgColor='brand.blue'
                transform='translate3d(-6px, -6px, 0)'
                color='white'
                fontSize='10px'
                letterSpacing='2px'
                textTransform='uppercase'
                border='solid 1px'
                borderColor='transparent'
                px={4}
                py={2}
                fontWeight='bold'
                _groupHover={{
                    bgColor: 'white',
                    borderColor: 'brand.blue',
                    color: 'brand.blue'
                }}
            >
                Rendez-vous
            </Box>
        </Box>

    )
}

const SocialNetworkButton = ({ href, label }) => {
    return (
        <Flex>

            <Link
                href={href}

                target='_blank'
                fontSize='10px'

                bgColor='transparent'
                borderRadius='none'

                color='brand.blue'
                p={0}
                pr={2}

                fontFamily='Lato'
                textTransform='uppercase'
                letterSpacing='widest'


                h='auto'
                alignItems='flex-start'

                _hover={{
                    bgColor: 'transparent',
                    textDecor: 'none'

                }}
                _focus={{
                    bgColor: 'transparent',
                    textDecor: 'none'

                }}
                _active={{
                    bgColor: 'transparent',
                    textDecor: 'none'
                }}
            >
                <Text
                    as='span'
                    borderBottom='solid 2px'
                    borderBottomColor='brand.blue'
                    pb='2'
                    mr='2'
                    fontWeight='bold'
                >
                    {label}
                </Text> →
            </Link>
        </Flex>
    )
}

export { AppointmentButton, SocialNetworkButton }


