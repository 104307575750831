import { Box } from '@chakra-ui/layout';
import React from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';

export default class ScrollContainer extends React.Component {
    static defaultProps = {
        scrollAxis: 'vertical',
    };

    constructor() {
        super();
        this.state = {
            scrollContainer: null,
        };
        this.scrollContainer = React.createRef();
    }

    componentDidMount() {
        this.setState({ scrollContainer: this.scrollContainer.current });
    }

    render() {
        return (
            <Box
                id='scroller'
                ref={this.scrollContainer}
                maxH='calc( 100vh - 98px )'
                overflow='scroll'
                className='noscrollbar'
            >
                <ParallaxProvider
                    scrollContainer={this.state.scrollContainer}
                    scrollAxis={this.props.scrollAxis}
                >
                    {this.props.children}
                </ParallaxProvider>
            </Box>
        );
    }
}