import { createIcon } from "@chakra-ui/icons"
import React from 'react'
// using `path`
export const NextIcon = createIcon({
    displayName: "NextIcon",
    viewBox: "0 0 35 10",
    path: (
        <polygon fill="currentColor" points="30.266 0 29.451 0.816 32.043 3.408 33.267 4.488 33.242 4.56 30.842 4.465 0 4.465 0 5.64 30.842 5.64 33.242 5.545 33.267 5.617 32.043 6.697 29.451 9.289 30.266 10.105 35.33 5.041"></polygon>
    )
})
export const PrevIcon = createIcon({
    displayName: "PrevArrow",
    viewBox: '0 0 35 10',
    path: (
        <path d="m5.0167 10 .8074-.8075-2.5678-2.565-1.2126-1.0689.0248-.0712 2.3776.094H35V4.4186H4.446l-2.3775.094-.0248-.0712 1.2126-1.0688L5.8241.8076 5.0167 0 0 5.0114 5.0167 10Z" fill="currentColor" />
    )
})

export const SpeakerIcon = createIcon({
    displayName: 'SpeakerIcon',
    viewBox: '0 0 20 20',
    path: (
        <>
            <polygon id="Path" stroke="#0836F2" stroke-linecap="square" points="10 0 10 14 5 10 0 10 0 4 5 4"></polygon>
            <path d="M10,9 C11.5,8 11.5,6 10,5" id="Path" stroke="#0836F2" stroke-linecap="round"></path>
            <path d="M13,11 C15.0858253,8.9141747 15.0858253,5.0858253 13,3" id="Path" stroke="#0836F2" stroke-linecap="square"></path>
        </>
    )
})

export const Circle = createIcon({
    displayName: 'SpeakerIcon',
    viewBox: '0 0 20 20',
    path: (
        <>
            <circle cx="10" cy="10" r="10" fill="currentColor" />
        </>
    )
})



export const ArrowDown = createIcon({
    displayName: "LeftDown",
    viewBox: '0 0  10 35',
    path: (
        <path d="M10 29.9833L9.19248 29.1759L6.62742 31.7437L5.55864 32.9563L5.48739 32.9315L5.5814 30.5539L5.5814 5.7943e-07L4.4186 7.31912e-07L4.41861 30.5539L4.51262 32.9315L4.44137 32.9563L3.37259 31.7437L0.807525 29.1759L3.93183e-06 29.9833L5.01139 35L10 29.9833Z" fill="currentColor" />
    )
})

export const ArrowDownSimple = createIcon({
    displayName: "ArrowDown",
    viewBox: '0 0  9 6',
    path: (
        <path fillRule="evenodd" clipRule="evenodd" d="M8.3 0L6.001 2.6L4.9 3.801H4.101H4L3 2.6L0.7 0L0 0.7L4.5 6L9 0.7L8.3 0Z" fill="currentColor" />
    )
})


export const Play = createIcon({
    displayName: 'Play',
    viewBox: '0 0 30 40',
    path: (
        <path opacity="0.6" fillRule="evenodd" clipRule="evenodd" d="M30 18L0 36V0L30 18Z" fill="currentColor" />
    )
})

