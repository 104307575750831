import { Image } from '@chakra-ui/image'
import { Box } from '@chakra-ui/layout';
import React from 'react';

import schema0en from '../images/schema-wwd-1-en.svg';
import schema1en from '../images/schema-wwd-2-en.svg';
import schema2en from '../images/schema-wwd-3-en.svg';
import schema3en from '../images/schema-wwd-4-en.svg';

import schema0fr from '../images/schema-wwd-1-fr.svg';
import schema1fr from '../images/schema-wwd-2-fr.svg';
import schema2fr from '../images/schema-wwd-3-fr.svg';
import schema3fr from '../images/schema-wwd-4-fr.svg';


import schema0frMobile from '../images/schema-wwd-1-fr-mobile.svg';
import schema1frMobile from '../images/schema-wwd-2-fr-mobile.svg';
import schema2frMobile from '../images/schema-wwd-3-fr-mobile.svg';
import schema3frMobile from '../images/schema-wwd-4-fr-mobile.svg';

import schema0enMobile from '../images/schema-wwd-1-en-mobile.svg';
import schema1enMobile from '../images/schema-wwd-2-en-mobile.svg';
import schema2enMobile from '../images/schema-wwd-3-en-mobile.svg';
import schema3enMobile from '../images/schema-wwd-4-en-mobile.svg';
import { useIntl } from 'react-intl';

const WhatWeDoSchema = ({ visibleBlock }) => {
    const blocks = [0, 1, 2, 3];
    const schemas = {
        fr: [
            schema0fr, schema1fr, schema2fr, schema3fr
        ],
        frMobile: [
            schema0frMobile, schema1frMobile, schema2frMobile, schema3frMobile
        ],
        en: [
            schema0en, schema1en, schema2en, schema3en
        ],
        enMobile: [
            schema0enMobile, schema1enMobile, schema2enMobile, schema3enMobile
        ]
    }
    const locale = useIntl()['locale'];
    return (
        <Box
            position='relative'
            w={{ base: '100%', lg: '460px' }}
            h={{ base: '35vh', lg: '460px' }}
            overflow='hidden'
        >


            {blocks.map((item, index) =>
                <Box
                    key={`WhatWeDoSchema-${index}-${locale}`}
                    position={{ base: 'absolute', lg: 'absolute' }}
                    top={0}
                    left={0}
                    w='100%'
                >
                    {/* <div>{visibleBlock}</div> */}
                    <Image
                        display={{ base: 'block', lg: 'none' }}
                        opacity={
                            visibleBlock === index ? 1 : 0
                        }
                        transition='opacity 2500ms ease-in-out, transform 1500ms ease-in-out'
                        transform={`rotate(${visibleBlock * 360}deg)`}

                        src={
                            item === 0 ? schemas[`${locale}Mobile`][0]
                                : item === 1 ? schemas[`${locale}Mobile`][1]
                                    : item === 2 ? schemas[`${locale}Mobile`][2]
                                        : item === 3 ? schemas[`${locale}Mobile`][3]
                                            : null
                        }
                        w={{ base: '240px', lg: '460px' }}
                        h={{ base: '240px', lg: '460px' }}
                        objectFit='contain'
                        alt='Shema What we do Going Public'
                        mx='auto'
                    />

                    <Image
                        display={{ base: 'none', lg: 'block' }}
                        opacity={
                            visibleBlock === index ? 1 : 0
                        }
                        transition='opacity 2500ms ease-in-out, transform 1500ms ease-in-out'
                        transform={`rotate(${visibleBlock * 360}deg)`}

                        src={
                            item === 0 ? schemas[locale][0]
                                : item === 1 ? schemas[locale][1]
                                    : item === 2 ? schemas[locale][2]
                                        : item === 3 ? schemas[locale][3]
                                            : null
                        }
                        w={{ base: '240px', lg: '460px' }}
                        h={{ base: '240px', lg: '460px' }}
                        objectFit='contain'
                        alt='Shema What we do Going Public'
                        mx='auto'
                    />
                </Box>

            )}
        </Box>


    )
}

export default WhatWeDoSchema