import { Heading, Flex } from '@chakra-ui/layout';
import React from 'react';

const BgTitleWired = ({ children }) => (
    <Flex
        minH='100%'
        alignItems='flex-start'
        pl={{ base: 0, lg: 12 }}
        pt={{ base: 8, lg: 32 }}
    >
        <Heading
            // display={{ base: 'none', lg: 'block' }}

            color='white'
            opacity='.25'
            textTransform='uppercase'
            // textShadow={`-1px -1px 0 #00F,  1px -1px 0 #00F, -1px 1px 0 #00F, 1px 1px 0 #00F;`}

            style={{
                "WebkitTextStroke": '.25px',
                "WebkitTextStrokeColor": '#0836f2'
            }}

            fontWeight='normal'
            fontSize={{ base: '6xl', lg: '9xl' }}
            fontFamily='Inknut Antiqua'
            zIndex='base'
        // wordBreak='break-all'
        >
            {children}
        </Heading>
    </Flex>

)

export default BgTitleWired