import { Box, Center, Flex, VStack, Grid, Image, Text } from '@chakra-ui/react';
import React from 'react';
import { CloseIcon } from '@chakra-ui/icons'

import { Element, animateScroll as scroll } from 'react-scroll'
import Logo from '../images/logo-blue.svg';
import { Circle } from '../Icons/Customs';
import { FormattedMessage } from 'react-intl';


const HomeMoto = ({ isVisible, data }) => {

    const Counter = ({ value }) => (

        <Center
            borderRadius='full'
            border='solid 1px'
            borderColor='brand.blue'
            mr={6}
            h={'22px'}
            w={'22px'}
            color='brand.blue'
            flexShrink={0}
            fontSize='xs'
        >
            {value}
        </Center>
    )

    return (
        // <SlideTop in={ isVisible }>
        <Element name='why' id='why'>

            <Box
                display={isVisible ? 'block' : 'none'}
                minH='100vh'

                p='60px'
                bgColor='brand.blue'

                py={{ base: 12, lg: 24 }}
                px={{ base: 4, lg: 48 }}
                pb={{ base: 12, lg: 48 }}

            >
                <Flex
                    justifyContent='center'
                    mb={10}

                >
                    <Box
                        display={{ base: 'none', lg: 'none' }}
                        color='white'
                        cursor='pointer'
                        onClick={() => {
                            scroll.scrollToTop();
                        }
                        }
                    >
                        <Center>
                            <CloseIcon />
                        </Center>
                        <Text
                            py={2}
                            pt={8}
                            fontFamily='Inknut Antiqua'
                            borderBottom='solid 2px'
                            borderBottomColor='white'
                            fontSize='10px'
                            textTransform='uppercase'
                            letterSpacing='widest'
                            fontWeight='extrabold'

                        >
                            <FormattedMessage id='close' />
                        </Text>
                    </Box>
                </Flex>

                <Grid
                    templateColumns={{
                        base: '100%',
                        lg: '35% 65%'
                    }}
                >
                    <Box
                        color='white'
                        fontSize={{ base: 'base', lg: 'xl' }}
                    >
                        <Box
                            border='solid 1px'
                            borderColor='rgba(255,255,255, 0.15)'
                            mx={6}
                        >
                            <VStack
                                p={{ base: 10, lg: '60px' }}
                                pr={{ base: 10, lg: '70px' }}
                                spacing={4}
                            >
                                <Center>
                                    <Circle w='6px' />
                                </Center>
                                {data.fields.dataPrimary.thirdScreenContents.primary.map((item, index) =>
                                    <Text
                                        key={`${item}-${index}`}
                                        lineHeight={{ base: '30px', lg: '45px' }}
                                    >
                                        {item}
                                    </Text>
                                )}
                            </VStack>
                        </Box>
                    </Box>
                    <Box
                        border='solid 1px'
                        borderColor='rgba(255,255,255, 0.15)'
                        transform={{ base: 'none', lg: 'translateY(80px)' }}
                    >
                        <Box
                            bgColor='white'
                            h='100%'
                            transform={{ base: 'none', lg: 'translate3d(-40px, -40px, 0)' }}
                            p={'50px'}
                            pr='70px'
                        >
                            <Flex
                                alignItems='flex-end'
                                mb={10}
                                wrap={{ base: 'wrap', lg: 'nowrap' }}
                            >
                                <Image
                                    src={Logo}
                                    w='100px'
                                />
                                <Text
                                    color='brand.blue'
                                    ml={2}
                                    mb={1}
                                    textAlign={{ base: 'center', lg: 'left' }}
                                >
                                    {data.fields.dataPrimary.thirdScreenContents.secondary.introSentence}
                                </Text>
                            </Flex>
                            <VStack spacing={3} wrap='wrap'>
                                {data.fields.dataPrimary.thirdScreenContents.secondary.items.map((item, index) =>
                                    <Flex alignItems='flex-start' key={`${index}-index-moto`}>
                                        <Counter value={index + 1} />
                                        <Text fontSize='sm' color='brand.blue' maxW='500px'>
                                            {item}
                                        </Text>
                                    </Flex>
                                )}
                            </VStack>
                        </Box>

                    </Box>
                </Grid>

            </Box>
        </Element>
        // </SlideTop>
    )
}

export default HomeMoto