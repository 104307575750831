import { Box, Grid, VStack } from '@chakra-ui/layout'
import React, { useState } from 'react'
import Triptych from '../Layouts/Triptych';
import { ScrollingBlockSmall, ScrollingIntro } from '../Components/ScrollingBlock';
import WhatWeDoSchema from '../Components/WhatWeDoSchema';

const Scroller = React.memo(props => {
    const [visibleBlock, setVisibleBlock] = useState(0);

    // return (
    //     <>
    //         <Box
    //             h='calc(38vh - 10px)'
    //             p={4}
    //         >
    //             <WhatWeDoSchema visibleBlock={visibleBlock} />
    //         </Box>
    //         <Box
    //             position='relative'
    //         >
    //             <Box
    //                 w='100%'
    //                 h='50px'
    //                 pointerEvents='none'
    //                 position='absolute'
    //                 top='-1px'
    //                 left='0'
    //                 right='0'
    //                 zIndex='banner'
    //                 bgGradient="linear(to-b, #0836f2FF, #0836f200)"
    //             />
    //             <Box
    //                 overflowY='scroll'
    //                 h='calc(62vh - 10px)'
    //                 className='noscrollbar'
    //                 pt={8}
    //             >
    //                 <VStack
    //                     pt='0px'
    //                     spacing={10}
    //                 >
    //                     <ScrollingIntro
    //                         setVisibleBlock={() => setVisibleBlock(0)}
    //                         data={props.data.fields.dataPrimary.firstScreen}
    //                     />
    //                     {props.data.fields.dataPrimary.followingScreens.map((item, index) =>
    //                         <ScrollingBlockSmall
    //                             key={`scrollingBlock-${index}`}^
    //                             id={`scrollingBlock-${index}`}
    //                             items={item.items}
    //                             body={item.body}
    //                             scrollIndicator={index + 1 < props.data.fields.dataPrimary.followingScreens.length}
    //                             setVisibleBlock={() => setVisibleBlock(index + 1)}
    //                         />
    //                     )}
    //                 </VStack>
    //             </Box>
    //         </Box>
    //     </>
    // )

    return (
        <Grid
            gridTemplateRows={{
                base: '250px calc(100vh - 270px)'
            }}
        >
            <Box>
                <WhatWeDoSchema visibleBlock={visibleBlock} />
            </Box>
            <Box
                position='relative'
            >
                <Box
                    w='100%'
                    h='50px'
                    pointerEvents='none'
                    position='absolute'
                    top='-1px'
                    left='0'
                    right='0'
                    zIndex='banner'
                    bgGradient="linear(to-b, #0836f2FF, #0836f200)"
                />
                <Box
                    overflowY='scroll'
                    // className='noscrollbar'
                    maxH='calc(100vh - 270px)'
                >
                    <VStack
                        spacing={10}
                    >
                        <Box
                            mt={{ base: 8, lg: 0 }}
                        >
                            <ScrollingIntro
                                setVisibleBlock={() => setVisibleBlock(0)}
                                data={props.data.fields.dataPrimary.firstScreen}
                            />
                        </Box>
                        {props.data.fields.dataPrimary.followingScreens.map((item, index) =>
                            <ScrollingBlockSmall
                                key={`scrollingBlock-${index}`}
                                id={`scrollingBlock-${index}`}
                                items={item.items}
                                body={item.body}
                                scrollIndicator={index + 1 < props.data.fields.dataPrimary.followingScreens.length}
                                setVisibleBlock={() => {
                                    setVisibleBlock(index + 1);
                                    console.log('go to : ', index)
                                }}
                            />
                        )}
                    </VStack>
                </Box>
            </Box>
        </Grid>
    )
})



const WhatWeDoMobile = ({
    btnPrev,
    btnNext,
    data,
    handleGoHome
}) => {

    const Body = () => (
        <Box
            position='relative'
        >
            <Scroller data={data} />
        </Box>
    )

    return (
        <Box
            position='relative'
        >
            <pre>
                {/* { JSON.stringify( data, null, 3)} */}
            </pre>
            <Triptych
                col1={btnPrev}
                col2={<Body />}
                col3={btnNext}

                colorScheme={'blue'}
                logo={data.fields.displayLogo}
                handleGoHome={handleGoHome}
            />
        </Box>
    )
}

export default WhatWeDoMobile