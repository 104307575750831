import { Box, Flex, Grid } from '@chakra-ui/react';
import React from 'react';
import LocaleSwitcher from '../Components/LocaleSwitcher';
import Logo from '../Components/Logo';
import { Helmet } from 'react-helmet';

const Triptych = ({
    col1,
    col2,
    col3,
    colorScheme,
    logo,
    handleGoHome
}) => {

    // cons borderColor= colorScheme
    return (
        <>
            {/* { JSON.stringify( logo, null, 2 )} */}
            <Helmet>
                <script async src="https://tally.so/widgets/embed.js"></script>
            </Helmet>
            <Logo isVisible={logo} colorScheme={colorScheme} handleGoHome={handleGoHome} />
            <Grid

                p={{ base: 2, lg: 12 }}
                h='100%'
                minH='100vh'

                bg={colorScheme === 'blue' ? 'brand.blue' : 'transparent'}


                templateColumns={{
                    base: '2em 1fr 2em',
                    lg: `7em 1fr 7em`
                }}
            >
                <Flex
                    border='solid 1px'
                    borderColor={colorScheme === 'blue' ? 'rgba(255,255,255, 0.15)' : 'gray.100'}
                    alignItems={{ base: 'flex-start', lg: 'center' }}
                    justifyContent='flex-end'
                >
                    {col1}
                </Flex>
                <Box
                    border='solid 1px'
                    borderColor={colorScheme === 'blue' ? 'rgba(255,255,255, 0.15)' : 'gray.100'}
                    borderLeft='none'
                    borderRight='none'
                >
                    {col2}
                </Box>
                <Flex
                    border='solid 1px'
                    borderColor={colorScheme === 'blue' ? 'rgba(255,255,255, 0.15)' : 'gray.100'}

                    alignItems={{ base: 'flex-start', lg: 'center' }}
                    justifyContent='flex-start'

                    position='relative'
                >
                    <LocaleSwitcher colorScheme={colorScheme} />
                    {col3}
                </Flex>
            </Grid>
        </>
    )
}

export default Triptych