import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Swiper from "../Swiper";
import { IntlProvider } from "react-intl";
import { ContentfulClient, ContentfulProvider } from "react-contentful";
import { Box, ChakraProvider, extendTheme } from "@chakra-ui/react";
import "../Stylesheets/cursor.css";

import { translations } from "../Translations";

const contentfulClient = new ContentfulClient({
  accessToken: process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN,
  space: process.env.GATSBY_CONTENTFUL_SPACE_ID,
});

const colors = {
  brand: {
    blue: "#0836f2",
    900: "#1a365d",
    800: "#153e75",
    700: "#2a69ac",
  },
};

const theme = extendTheme({
  colors: { ...colors },
  styles: {
    global: {
      // styles for the `body`
      body: {
        //   bg: colors.brand[900],
        // color: "blue",
        fontFamily: "Lato",
        //   fontFamily: 'Inknut Antiqua'
      },
      // styles for the `a`
      a: {
        color: "teal.500",
        _hover: {
          textDecoration: "underline",
        },
      },
    },
  },
});

const Page = (props) => {
  const { pageContext } = props;
  const { page } = props.data;

  // return(
  //     <>
  //     <pre>
  //         ici :
  //      { JSON.stringify( pageContext, null, 2 )}
  //      </pre>
  //     </>
  // )
  return (
    <ChakraProvider theme={theme}>
      <IntlProvider
        messages={translations[pageContext.locale]}
        locale={pageContext.locale}
        defaultLocale="en"
      >
        <Helmet>
          <title>{page.title}</title>
          <meta name="description" content={page.description} />
          {page.sharingImage ? (
            // ?fm=jpg&w=1200&h=628
            <meta
              property="og:image"
              content={`${page.sharingImage.fixed.src}`}
            />
          ) : null}
          <meta property="og:description" content={page.description} />
          <meta property="og:type" content="article" />
          {/* <link rel="icon" type="image/svg+xml" href="/favicon-blue.svg" sizes="any" /> */}
        </Helmet>
        <Box position="relative">
          <ContentfulProvider client={contentfulClient}>
            <Swiper
              cmsDocumentID={page.contentful_id}
              initialIndex={page.position}
              locale={props.pageContext.locale}
            />
          </ContentfulProvider>
        </Box>
      </IntlProvider>
    </ChakraProvider>
  );
};

export default Page;

export const pageQuery = graphql`
  query pageQuery($contentfulID: String!) {
    page: contentfulPage(contentful_id: { eq: $contentfulID }) {
      node_locale
      contentful_id
      slug
      description
      title
      sharingImage {
        fixed {
          src
        }
      }
      position
    }
  }
`;
