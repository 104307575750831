import { Box, Grid, Text, Flex } from '@chakra-ui/react';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-scroll';
import { NextIcon } from '../Icons/Customs';

const BtnNextDesktop = ({ text, colorScheme, index }) => (
    !text ? null :
        <Grid
            color={colorScheme === 'blue' ? 'white' : 'brand.blue'}
            templateColumns={{ base: '1fr 1fr', lg: '100%' }}
            transform={{ base: 'rotate(90deg) translateY(58px)', lg: 'none' }}
            cursor='pointer'
            role='group'
        >

            <Box
                display={{ base: 'block', lg: 'none' }}
                mb={{ base: 0, lg: 2 }}
                transform='translateY(-4px)'
                mr='2'
            >
                ↑
            </Box>
            <Box
                display={{ base: 'none', lg: 'block' }}
                mb={{ base: 0, lg: 2 }}
                transition='transform 200ms ease-in-out'
                _groupHover={{
                    transform: 'translateX(10px)'
                }}
            >
                <NextIcon
                    w='30px'
                />
            </Box>
            <Text
                w={{ base: '260px', lg: 'auto' }}
                fontSize={{ base: '9px', lg: '10px' }}
                letterSpacing='widest'

                whiteSpace={{ base: 'prev', lg: 'normal' }}

                fontFamily='Inknut Antiqua'
                fontWeight='thin'
                textTransform='uppercase'
            >
                {text}
            </Text>
        </Grid>
)

const BtnNextMobile = ({ text, colorScheme }) => (
    !text ? null :
        <Flex
            style={{
                "writingMode": "vertical-rl",
                "textOrientation": "mixed"
            }}

            pt='40vh'
            color={colorScheme === 'blue' ? 'white' : 'brand.blue'}


            letterSpacing='widest'

            whiteSpace={{ base: 'prev', lg: 'normal' }}

            fontFamily='Inknut Antiqua'
            fontWeight='thin'
            textTransform='uppercase'
            fontSize='11px'
            pl='6px'
            alignItems='flex-start'
        >
            <Text as='span' fontFamily='Lato' mb={2}>↑</Text>
            {text}
        </Flex>
)

const BtnNext = (props) => (

    <Link
        to={`swiper-${props.index + 1}`}
        horizontal={true}
        spy={true}
        smooth={true}
        duration={500}
        containerId='container'
    >
        {isMobile ?
            <BtnNextMobile {...props} />
            :
            <BtnNextDesktop {...props} />
        }
    </Link>

)

export default BtnNext