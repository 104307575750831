import { Box, Button } from '@chakra-ui/react';
import React, { useState } from 'react';
import ReactPlayer from 'react-player'
import Fade from '../Transitions/Fade';
import { CloseIcon } from '@chakra-ui/icons'


const Video = ({ isVisible, handleClose, handleVideoEnded }) => {

    return (
        <Fade in={isVisible}>
            <Box
                position='absolute'
                zIndex={'tooltip'}
                top={0}
                bottom={0}
                h='100vh'
                w='100vw'

                bg='black'

                px={{ base: 0, lg: '60px' }}
                py={{ base: '20px', lg: '60px' }}

                pointerEvents={isVisible ? 'auto' : 'none'}

            >
                <Button
                    onClick={() => {
                        // setIsPlaying( false );
                        handleVideoEnded();
                        handleClose();
                    }}
                    position='absolute'
                    top={'20px'}
                    right='20px'
                    bgColor='transparent'
                    color='white'
                    zIndex='tooltip'
                ><CloseIcon /></Button>
                <ReactPlayer
                    url={
                        [
                            // 'https://wanaboat.s3.eu-west-3.amazonaws.com/going-public/video.webm',
                            // 'https://wanaboat.s3.eu-west-3.amazonaws.com/going-public/GOING_PUBLIC_PAD_H264_5mbs_SD.mp4'
                            `https://goingpublicstorage.s3.eu-west-3.amazonaws.com/GOING_PUBLIC_PAD_H264_5mbs.mp4`
                        ]
                    }
                    playing={isVisible}
                    width='100%'
                    height='100%'
                    controls
                    autoPlay
                    preload={"value".toString()}
                    onEnded={() => {
                        handleVideoEnded();
                        handleClose();
                    }}
                />
            </Box>
        </Fade>
    )
}

export default Video