import { Box, Heading } from '@chakra-ui/layout'
import React from 'react'

const Title = ({ children }) => (
    <Box
        position='relative'

    >
        <Heading

            position='absolute'
            top={0}
            left={'2px'}

            color='white'
            pr={4}

            // opacity='.4'
            textTransform='uppercase'
            // textShadow={`4px 0px 0 #fff, 1px 1px 0 #fff, 4px -1px 0 #00f;`}

            style={{
                "WebkitTextStroke": '.25px',
                "WebkitTextStrokeColor": 'blue'
            }}

            fontWeight='normal'
            fontSize={{ base: '3xl', lg: '6xl' }}
            fontFamily='Inknut Antiqua'
        >
            {children}

        </Heading>
        <Heading
            as='div'
            // display='none'

            position='absolute'
            top={0}
            left={0}

            color='brand.blue'
            pr={4}

            // opacity='.4'
            textTransform='uppercase'
            // textShadow={`4px 0px 0 #fff, 1px 1px 0 #fff, 4px -1px 0 #00f;`}

            // style={{
            //     "-webkit-text-stroke":'1px',
            //     "-webkit-text-stroke-color":'blue'
            // }}

            fontWeight='normal'
            fontSize={{ base: '3xl', lg: '6xl' }}
            fontFamily='Inknut Antiqua'
        >
            {children}

        </Heading>
        <Heading
            as='div'
            // display='none'
            visibility='hidden'

            color='brand.blue'
            pr={4}
            // opacity='.4'
            textTransform='uppercase'
            // textShadow={`4px 0px 0 #fff, 1px 1px 0 #fff, 4px -1px 0 #00f;`}

            // style={{
            //     "-webkit-text-stroke":'1px',
            //     "-webkit-text-stroke-color":'blue'
            // }}

            fontWeight='normal'
            fontSize={{ base: '3xl', lg: '6xl' }}
            fontFamily='Inknut Antiqua'
        >
            {children}

        </Heading>
    </Box>
)
export default Title