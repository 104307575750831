import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { Box, Text, HStack, Link } from '@chakra-ui/react';
import { useIntl } from 'react-intl';

const LocaleSwitcher = ({ colorScheme }) => {
    const locale = useIntl().locale;
    return (
        <>
            <Box
                position='absolute'
                top={{ base:4, lg:10 }}
                left={0}

                fontFamily='Inknut Antiqua'
                textTransform='uppercase'
                fontSize={{ base: '9px', lg: '10px' }}
                letterSpacing='wide'

                className='test'
                w={{ base:'25px', lg:'auto'}}
            >
                <HStack
                    transform={{ base: 'rotate(90deg) translateY(0px)', lg: 'none' }}
                >
                    {colorScheme === 'blue' ?
                        <>
                            <Link
                                color={locale === 'fr' ? '#FFF' : '#FFFFFFAA'}
                                as={GatsbyLink} to={`/fr/`}>fr</Link>
                            <Text color='#FFFFFFEE'>|</Text>
                            <Link
                                color={locale === 'en' ? '#FFF' : '#FFFFFFAA'}
                                as={GatsbyLink} to={`/en/`}>en</Link>
                        </>
                        :
                        <>
                            <Link
                                color={locale === 'fr' ? 'black' : 'gray.500'}
                                as={GatsbyLink} to={`/fr/`}>fr</Link>
                            <Text>|</Text>
                            <Link
                                color={locale === 'en' ? 'black' : 'gray.500'}
                                as={GatsbyLink} to={`/en/`}>en</Link>
                        </>
                    }

                </HStack>
            </Box>
        </>
    )
}

export default LocaleSwitcher