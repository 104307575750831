import React, { useEffect, useState } from "react";
import DefaultScreen from "../Screens/default";
import { useContentful } from "react-contentful";
import { isMobile } from "react-device-detect";

// import OverflowContainer from '../Utils/OverflowContainer';

import BtnPrev from "./BtnPrev";
import BtnNext from "./BtnNext";
import { Box, Center, Flex, Spinner } from "@chakra-ui/react";

import "../Stylesheets/no-scrollbar.css";

import { scroller, Events } from "react-scroll";

const Swiper = ({ locale, initialIndex }) => {
  const [initialView, setInitialView] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(5);

  const { data, error, fetched, loading } = useContentful({
    locale: locale,
    contentType: "page",

    limit: 100,
    query: {
      order: "fields.position",
    },

    // parser: () => alert('cms load finish')
  });

  if (data && initialView) {
    setInitialView(false);
    setTimeout(() => {
      scroller.scrollTo("swiper-5", {
        horizontal: true,
        duration: 50,
        delay: 0,
        smooth: true,
        containerId: "container",
      });
    }, 0);
  }

  Events.scrollEvent.register("end", function (to, element) {
    let newIndex = to ? parseInt(to.split("-")[1]) : null;
    setCurrentIndex(newIndex);

    if (newIndex) {
      setHistory(newIndex);
    }
  });

  const setHistory = (index) => {
    let currentEntry;
    let slug = "";
    currentEntry = data.items[index];

    // console.log(data, index, currentEntry);
    // console.log(index);

    // if (index < 0) {
    //   slug = data.items[7].fields.slug;
    //   console.log('slug', slug)
    //   currentEntry = data.items[7]
    // }
    // if (index > 7) {
    //   currentEntry = data.items[0]
    // }
    // else {
    //   currentEntry = data.items[index]
    // }

    window.history.pushState(
      {},
      "",
      currentEntry || slug
        ? `/${locale}${slug ? slug : currentEntry.fields.slug}`
        : "/"
    );
  };

  if (loading || !fetched || error || !data) {
    return null;
  }

  if (loading && !data) {
    return (
      <Center h="100vh" w="100vw">
        <Spinner />
      </Center>
    );
  } else if (data) {
    return (
      <Box overflowX={"hidden"}>
        <Flex
          h="100vh"
          overflowX={{ base: "scroll", lg: "hidden" }}
          wrap="nowrap"
          style={
            isMobile
              ? {
                  scrollSnapType: "x mandatory",
                  // scrollSnapPointsY: "repeat(100vw)",
                  webkitOverflowScrolling: "touch",
                }
              : null
          }
          id="container"
          className="no-scrollbar"
        >
          {data.items.map((item, index) => (
            <Box
              style={{ scrollSnapAlign: "start" }}
              w="100vw"
              flexShrink={0}
              id={`swiper-${index}`}
              key={item.sys.id}
            >
              {/* <pre>
                currentIndex:
                {JSON.stringify(currentIndex, null, 2)}
              </pre>
              <pre>
                index:
                {JSON.stringify(currentIndex, null, 2)}
              </pre> */}
              <DefaultScreen
                cmsDocumentID={item.sys.id}
                isCurrent={index === currentIndex}
                locale={locale}
                realIndex={index}
                index={0}
                handleGoHome={() => {
                  // console.log("handle go home");
                  setCurrentIndex(6);
                }}
                btnPrev={
                  index > 0 ? (
                    <BtnPrev
                      index={index}
                      text={`${
                        item.fields.navNextLabel
                          ? item.fields.navPrevLabel
                          : data.items[index - 1]
                          ? data.items[index - 1].fields.navLabel
                          : null
                      }`}
                      colorScheme={data.items[index].fields.colorScheme}
                    />
                  ) : null
                }
                btnNext={
                  index < 9 ? (
                    <BtnNext
                      index={index}
                      text={`${
                        item.fields.navNextLabel
                          ? item.fields.navNextLabel
                          : data.items[index + 1]
                          ? data.items[index + 1].fields.navLabel
                          : null
                      }`}
                      colorScheme={data.items[index].fields.colorScheme}
                    />
                  ) : null
                }
              />
            </Box>
          ))}
        </Flex>
      </Box>
    );
  }
};

export default Swiper;
