import { Box, Center, Flex, Stack, Text, VStack } from '@chakra-ui/layout'
import React, { useState } from 'react'
import 'custom-cursor-react/dist/index.css';
import HomepageVideoPlayer from '../Player/HomepageVideo';
import logoSrc from '../images/logo-blue.svg'
import { Image } from '@chakra-ui/image';
import { ArrowDownIcon } from '@chakra-ui/icons';
import HomeMoto from '../Components/HomeMoto';

import { Element, scroller } from 'react-scroll'
import { Circle, Play } from '../Icons/Customs';
import { FormattedMessage } from 'react-intl';
import Triptych from '../Layouts/Triptych';
import Video from '../Components/Video';
// import playButton from '../images/play.gif'
import ButtonPlayMp4 from '../images/play-button.mp4'
import ButtonPlayWebm from '../images/play-button.webm'
const Home = ({
    btnPrev,
    btnNext,
    data,
    isCurrent
}) => {
    // const [isVideoViewed, setVideoViewed] = useState(false);

    const [isVideoVisible, setVideoVisible] = useState(false);
    const [isMotoVisible, setMotoVisible] = useState(false);

    const handleCloseVideo = () => {
        setVideoVisible(false);
    }
    // const handleCloseMoto = () => {
    //     setMotoVisible(false);
    // }

    const PlayButton = ({ isVisible }) => (
        <Box
            onClick={() => {
                setVideoVisible(!isVideoVisible);
            }}

            visibility={!isVisible ? 'hidden' : 'initial'}

            borderRadius='full'
            w='100px'
            h='100px'
            position='relative'
            cursor='pointer'
            role='group'
        >
            <Box
                as='video'
                autoPlay
                muted
                loop
                playsInline
                webkit-playsinline="true"
                objectFit={'cover'}
                borderRadius='full'
                border='solid 2px'
                borderColor='brand.blue'
                h='100px'
                pointerEvents='none'
            >
                {/* <source type='video/webm' src={ButtonPlayWebm} /> */}
                <source type='video/mp4' src={ButtonPlayMp4} />
            </Box>

            <Box
                borderRadius='full'
                w='100px'
                h='100px'
                color='white'
                position='absolute'
                top='0'
                left='0'
                zIndex='tooltip'
                bgColor='brand.blue'
                opacity='0'
                _groupHover={{
                    opacity: 1
                }}
                transition='opacity 100ms ease-in-out'
            />

            <Center
                w='100px'
                h='100px'
                color='white'
                position='absolute'
                top='0'
                left='0'
                zIndex='tooltip'
            >
                <Play
                    pl='5px'
                    mt='1px'
                    w='40px'
                    h='40px'
                    position='relative'
                    zIndex='tooltip'
                />
            </Center>



        </Box>
    )

    const Body = () => (
        <>
            <Box
                h={{ base: 'calc(100vh - 20px)', lg: 'calc(100vh - 98px)' }}
                overflowY='scroll'
                className='noscrollbar'
                id='scroller'
            >
                <Center
                    minH={{ base: 'calc(100vh - 20px)', lg: 'calc(100vh - 120px)' }}
                    pb={{ base: '32', lg: 0 }}
                >
                    <Stack spacing={{ base: '50px', lg: '80px' }}>
                        <Flex w='100%' justifyContent='center'>
                            <PlayButton isVisible={false} />
                        </Flex>
                        <Box w='100%'>
                            <Image
                                src={logoSrc}
                                w={{ base: '160px', lg: '2xs' }}
                            />
                        </Box>
                        <Flex w='100%' justifyContent='center'>
                            <PlayButton isVisible={true} />
                        </Flex>
                    </Stack>
                </Center>
                <Box>
                    <Element name='intro' id='intro'>
                        <Center
                            minH='100vh'
                            py={10}
                        >
                            <VStack
                                color='brand.blue'
                                px={{ base: 6, lg: 48 }}
                                lineHeight={{ base: 7, lg: 10 }}
                                fontSize={{ base: '14px', lg: 'xl' }}
                                spacing={6}
                            >
                                {data.fields.dataPrimary.secondScreenContents.map((item, index) =>
                                    <Box key={`home-secondScreenContents-${item}-${index}`}>
                                        <Text
                                            fontFamily='Inknut Antiqua'
                                        >{item.primary}</Text>
                                        <Text
                                            fontFamily='Lato'
                                        >{item.secondary}</Text>
                                    </Box>

                                )}
                                <Box fontSize='4xl'>
                                    <Circle w={{ base: '4px', lg: '6px' }} />
                                </Box>
                                <Flex>
                                    <Box
                                        color='brand.blue'
                                        fontFamily='Inknut Antiqua'
                                        textAlign='center'

                                        textTransform='uppercase'
                                        letterSpacing='widest'
                                        fontSize={{ base: '9px', lg: 'xs' }}
                                        fontWeight='semibold'

                                        // cursor='pointer'
                                        mb={{ base: 32, lg: 0 }}


                                    // onClick={() => {
                                    //     setMotoVisible(true);
                                    //     scroller.scrollTo('why', {
                                    //         spy: true,
                                    //         duration: 800,
                                    //         delay: 500,
                                    //         smooth: 'easeInOutQuart'
                                    //     })
                                    // }}
                                    >
                                        <Text
                                            borderBottom='solid 1px'
                                            borderBottomColor='brand.blue'
                                        >
                                            <FormattedMessage id='home.raison.etre' />
                                        </Text>
                                        <Box>
                                            <ArrowDownIcon />
                                        </Box>
                                    </Box>


                                </Flex>
                            </VStack>
                        </Center>
                    </Element>
                </Box>
            </Box>
        </>
    )

    return (
        <Box
            position='relative'
        >
            <Triptych
                col1={btnPrev}
                col2={<Body />}
                col3={btnNext}
                logo={data.fields.displayLogo}
            />
            {isVideoVisible ?
                <Video
                    isVisible={isVideoVisible}
                    handleClose={
                        () => {
                            setVideoVisible(false);
                        }
                    }
                    handleVideoEnded={() => {
                        // setVideoViewed(true);
                        setTimeout(() => {
                            scroller.scrollTo('intro', {
                                // spy: true,
                                smooth: true,
                                duration: 800,
                                containerId: "scroller",

                                // duration: 800,
                                delay: 500,
                                smooth: 'easeInOutQuart'
                            });
                        }
                            , 500)
                    }}
                />
                : null}
            {/* <pre>
                isCurrent:
                {JSON.stringify(isCurrent, null, 2)}
            </pre>
            <pre>
                isCurrent:
                {JSON.stringify(isVideoVisible, null, 2)}
            </pre> */}
            <HomeMoto
                isVisible={isCurrent && !isVideoVisible}
                data={data}
            />
        </Box>
    )
}

export default Home