import { Box, Flex, Grid, Text } from '@chakra-ui/react';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-scroll';
import { PrevIcon } from '../Icons/Customs';

const BtnPrevDesktop = ({ handleClick, text, colorScheme }) => (
    !text ? null :
        <Grid
            color={colorScheme === 'blue' ? 'white' : 'brand.blue'}
            templateColumns={{ base: '1fr 1fr', lg: '100%' }}
            transform={{ base: 'rotate(90deg) translateY(-50px)', lg: 'none' }}
            cursor='pointer'
            role='group'
        >

            <Box
                display={{ base: 'block', lg: 'none' }}
                mb={{ base: 0, lg: 2 }}
                transform='translateY(-4px)'
                mr='2'
            >
                ↓
            </Box>
            <Flex
                display={{ base: 'none', lg: 'flex' }}
                justifyContent={{ base: 'flex-start', lg: 'flex-end' }}
                mb={{ base: 0, lg: 2 }}
                transition='transform 200ms ease-in-out'
                _groupHover={{
                    transform: 'translateX(-10px)'
                }}
            >
                <PrevIcon
                    w='30px'
                />
            </Flex>
            <Text
                w={{ base: '260px', lg: 'auto' }}
                textAlign={{ base: 'left', lg: 'right' }}
                fontSize={{ base: '9px', lg: '10px' }}
                letterSpacing='widest'

                whiteSpace={{ base: 'prev', lg: 'normal' }}

                fontFamily='Inknut Antiqua'
                fontWeight='thin'
                textTransform='uppercase'
            >
                {text}
            </Text>
        </Grid>
)

const BtnPrevMobile = ({ handleClick, text, colorScheme }) => (
    !text ? null :
        <Flex
            style={{
                "writingMode": "vertical-rl",
                "textOrientation": "mixed"
            }}

            color={colorScheme === 'blue' ? 'white' : 'brand.blue'}

            transform='rotate(-180deg)'

            letterSpacing='widest'

            whiteSpace={{ base: 'prev', lg: 'normal' }}

            fontFamily='Inknut Antiqua'
            fontWeight='thin'
            textTransform='uppercase'
            fontSize='11px'

            pb='40vh'
            pl='6px'


        >
            {text}
            <Text as='span' fontFamily='Lato' mt={2}>↑</Text>
        </Flex>
)


const BtnPrev = (props) => (

    props.index === 0 ? null :
        <Link
            to={`swiper-${props.index - 1}`}
            horizontal={true}
            spy={true}
            smooth={true}
            duration={500}
            containerId='container'
        >
            {isMobile ?
                <BtnPrevMobile {...props} />
                :
                <BtnPrevDesktop {...props} />
            }
        </Link>
)

export default BtnPrev