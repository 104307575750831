import { Box, Flex, Center, Grid, Stack, Text } from '@chakra-ui/react';
import { VStack } from '@chakra-ui/layout';
import React from 'react';
import { Parallax } from 'react-scroll-parallax';
import { ArrowDown, Circle } from '../Icons/Customs';

import VisibilitySensor from "react-visibility-sensor";


const ScrollIndicator = () => (
    <Box
    >
        <Center>
            <ArrowDown h='35px' w='10px' />
        </Center>
    </Box>
)

const ScrollingBlockSmall = ({ items, body, scrollIndicator, id, setVisibleBlock }) => {
    const onChange = (isVisible) => {
        if (isVisible) {
            setVisibleBlock()
        }
    }
    return (
        <VisibilitySensor
            key={'list'}
            partialVisibility={true}
            onChange={onChange}
            // containment={ document.querySelector('#scroller')}
        >
            {({ isVisible }) => {
                return (
                    <Box minH=''>
                        <Grid
                            templateColumns={{
                                base: '100%',
                                lg: '70% 30%'
                            }}
                            maxW='500px'
                        >
                            <Box>

                                <Box
                                    border='solid 1px'
                                    borderColor='rgba(255,255,255, 0.5)'
                                    px={{ base: 8, lg: 16 }}
                                    py={{ base: 6, lg: 20 }}
                                    pb='20'
                                    mx={4}
                                    color='white'

                                    bgColor='brand.blue'

                                >
                                    <Stack spacing='20px'>
                                        <Center><Circle w='4px' /></Center>
                                        <Text
                                            fontFamily='Inknut Antiqua'
                                            fontSize='small'
                                            fontWeight='normal'
                                            textAlign='center'
                                            lineHeight='6'
                                        >
                                            {body}
                                        </Text>
                                        {scrollIndicator ?
                                            <ScrollIndicator />
                                            : null}
                                    </Stack>
                                </Box>
                            </Box>
                            <Flex
                                justifyContent='center'
                            >
                                <Box
                                    w='60%'
                                    border='solid 1px'
                                    borderColor='rgba(255,255,255, 0.15)'
                                    color='brand.blue'

                                    bgColor='white'
                                    transform='translate3d( 0px, -40px, 0)'
                                    p={8}
                                    mb={2}
                                >
                                    <VStack spacing='4'>
                                        {items.map((item, index) =>
                                            <Box key={index}>
                                                <Box>
                                                    <Center>
                                                        <Center
                                                            w='15px'
                                                            h='15px'
                                                            border='solid 1px'
                                                            p='2px'
                                                            borderColor='brand.blue'
                                                            lineHeight='1'
                                                            borderRadius='full'
                                                            fontSize='9px'>
                                                            {index + 1}
                                                        </Center>
                                                    </Center>
                                                    <Text fontSize='xs' maxW='90px' textAlign='center'>
                                                        {item}
                                                    </Text>
                                                </Box>
                                            </Box>
                                        )}
                                    </VStack>
                                </Box>
                            </Flex>
                        </Grid>
                    </Box>
                );
            }}
        </VisibilitySensor>
    )
}

const ScrollingBlockLarge = ({ items, body, scrollIndicator, setVisibleBlock , isLast}) => {
    const onChange = (isVisible) => {
        if (isVisible) {
            setVisibleBlock()
        }
    }
    return (
        <VisibilitySensor
            partialVisibility={true}
            onChange={onChange}
            // containment={ document.querySelector('#scroller')}
        >
            {({ isVisible }) => {
                return (
                    <Box minH= {!isLast ? '80vh' : 'auto'}>
                        <Grid
                            templateColumns={{
                                base: '100%',
                                lg: '70% 30%'
                            }}
                            maxW='500px'
                        >
                            <Box>
                                <Parallax y={[-60, 10]}>

                                    <Box
                                        border='solid 1px'
                                        borderColor='rgba(255,255,255, 0.5)'
                                        px={{ base: 0, lg: 16 }}
                                        py={{ base: 0, lg: 20 }}
                                        color='white'

                                        bgColor='brand.blue'

                                    >
                                        <Stack spacing='20px'>
                                            <Center><Circle w='4px' /></Center>
                                            <Text
                                                fontFamily='Inknut Antiqua'
                                                fontSize='medium'
                                                fontWeight='normal'
                                                textAlign='left'
                                                lineHeight='10'
                                            >
                                                {body}
                                            </Text>
                                            {scrollIndicator ?
                                                <ScrollIndicator />
                                                : null}
                                        </Stack>
                                    </Box>
                                </Parallax>
                            </Box>
                            <Box>
                                <Parallax y={[-10, 10]}>

                                    <Box
                                        border='solid 1px'
                                        borderColor='rgba(255,255,255, 0.5)'
                                        transform='translate3d( -20px, -20px, 0)'
                                        bgColor='brand.blue'
                                        color='brand.blue'
                                    >
                                        <Box
                                            bgColor='white'
                                            transform='translate3d( -20px, -20px, 0)'
                                            p={8}
                                        >
                                            <VStack spacing='4'>
                                                {items.map((item, index) =>
                                                    <Box key={index}>
                                                        <Box>
                                                            <Center>
                                                                <Center
                                                                    w='15px'
                                                                    h='15px'
                                                                    border='solid 1px'
                                                                    p='2px'
                                                                    borderColor='brand.blue'
                                                                    lineHeight='1'
                                                                    borderRadius='full'
                                                                    fontSize='8px'
                                                                >
                                                                    {index + 1}
                                                                </Center>
                                                            </Center>
                                                            <Text fontSize='xs' maxW='90px' textAlign='center'>
                                                                {item}
                                                            </Text>
                                                        </Box>
                                                    </Box>
                                                )}
                                            </VStack>
                                        </Box>
                                    </Box>
                                </Parallax>
                            </Box>
                        </Grid>
                    </Box>
                );
            }}
        </VisibilitySensor>
    )
}

const ScrollingIntro = ({ setVisibleBlock, data }) => {
    const onChange = (isVisible) => {
        if (isVisible) {
            setVisibleBlock( 0 )
        }
    }

    return (
        <VisibilitySensor
            partialVisibility={false}
            onChange={onChange}
            // containment={ document.querySelector('#scroller')}
        >
            <Center
                mx={{ base: 0, lg:4 }}
                mt={{ base: 0, lg:12 }}
                minH='calc( 100vh - 120px )'
            >
                <Flex
                    justifyContent={{ base:'center', lg:'flex-start' }}
                >
                    <Box
                        border='solid 1px'
                        borderColor='rgba(255,255,255, 0.5)'
                        p={{ base:8, lg:12 }}
                        // mt='300px'
                        w={{ base:'80%', lg:'65%'}}
                        color='white'
                        bgColor='brand.blue'
                        fontFamily='Inknut Antiqua'
                        fontSize={{ base:'large', lg:'2xl' }}
                    >
                        <VStack
                            spacing={6}
                        >
                            {/* <Center>
                                <Circle w='6px' />
                            </Center> */}
                            <Text textAlign='center' lineHeight={{ base:'tall',lg:'40px'}}>
                                <WireTitle>
                                    { data && data['title1.1'] ? data['title1.1'] : '' }
                                    <br />
                                    { data && data['title1.2'] ? data['title1.2'] : '' }
                                </WireTitle>
                                <br />
                                { data && data.title2 ? data.title2 : '' }
                            </Text>
                            <Box pt={6}>
                                <ScrollIndicator />
                            </Box>
                        </VStack>
                    </Box>
                </Flex>
            </Center>
        </VisibilitySensor>
    )
}

const WireTitle = ({ children }) => (
    <Box
        as='span'
        fontFamily='Inknut Antiqua'
        fontWeight='light'
        color='brand.blue'
        opacity='1'
        mb={4}
        display='inline-block'
        style={{
            "WebkitTextStroke":'.5px',
            "WebkitTextStrokeColor":'#FFF'
        }}
    >
        {children}
    </Box>
)

export { ScrollingBlockLarge, ScrollingBlockSmall, ScrollingIntro }