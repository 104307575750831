import { Image } from '@chakra-ui/image';
import { Box, Center, Flex, Grid, Heading, Stack, Text } from '@chakra-ui/layout'
import React from 'react'
import Triptych from '../Layouts/Triptych';
import schema from '../images/what-we-do.svg';
import BgTitleWired from '../Components/BgTitleWired';
import pixel from '../images/pixel.svg'
import pixelGray from '../images/pixel-gray.svg'
import { ArrowDownSimple, Circle as Point } from '../Icons/Customs';

const Technology = ({
    btnPrev,
    btnNext,
    data,
    handleGoHome
}) => {

    const Circle = ({ children, isLast }) => (
        <Box
            position='relative'
        >
            <Center
                position='absolute'
                top='-12px'
                left={{ base: '0px', lg: 0 }}
                right='0'
                zIndex='banner'
                color='brand.blue'

            >
                <ArrowDownSimple w={{ base: '8px', lg: '12px' }} />
            </Center>
            <Center
                position='absolute'
                bottom='-6px'
                left='0'
                right='0'
                zIndex='banner'
            >
                <Box
                    bg='brand.blue'
                    w='10px'
                    h='10px'
                    borderRadius='full'
                    border='solid 2px'
                    borderColor='white'
                />
            </Center>
            <Box>
                <Center
                    bgColor='brand.blue'
                    borderRadius='full'
                    w={{ base: '60px', lg: '140px' }}
                    h={{ base: '60px', lg: '140px' }}
                    transform='translateY(4px)'
                >
                    <Center
                        border='solid 1px'
                        bgColor='white'
                        borderColor='brand.blue'

                        borderRadius='full'
                        w={{ base: '60px', lg: '140px' }}
                        h={{ base: '60px', lg: '140px' }}
                        transform='translateY(-4px)'

                        color='brand.blue'
                        textTransform='uppercase'
                        letterSpacing={{ base: '0px', lg: '2px' }}
                        fontSize={{ base: '6.5px', lg: '9px' }}
                        textAlign='center'
                        fontWeight='700'

                        px={{ base: 2, lg: 8 }}
                        textOverflow='clip'
                    // lineHeight
                    >
                        {children}
                    </Center>
                </Center>
            </Box>
        </Box>
    )

    const Rect = ({ children }) => (
        <Box
            bgColor='white'
            border='solid .5px'
            borderColor='gray.100'
            w='100%'
            my={{ base: 2, lg: 4 }}
        >
            <Box
                transform='translateY(-2px)'
                border='solid 1px'
                borderColor='gray.200'
                p={{ base: 1, lg: 8 }}
                textAlign='center'
                color='gray.900'
                fontSize={{ base: '10px', lg: 'sm' }}
                bgColor='white'
            >
                {children}
            </Box>
        </Box>
    )

    const Line = ({ col1, col2, col3, isLast }) => (
        <Grid
            gridTemplateColumns={{
                base: '1fr 55px 1fr',
                lg: '40% 20% 40%'
            }}
            gap='2'
            maxW='900px'
            mx='auto'

            borderBottom={isLast ? 'solid 1px' : null}
            borderBottomColor='gray.100'

        >
            <Center
                bgImage={pixelGray}
                bgPos='center'
                bgRepeat='repeat-y'
            >
                {col1}
            </Center>
            <Center
                py={6}
                pb={isLast ? 0 : 6}
                bgImage={pixel}
                bgPos='center'
                bgRepeat='repeat-y'
            >
                {col2}
            </Center>
            <Center
                bgImage={pixelGray}
                bgPos='center'
                bgRepeat='repeat-y'
            >
                {col3}
            </Center>
        </Grid>
    )

    const Body = () => (
        <Grid
            templateColumns={{
                base: '100%',
                lg: '0% 100%'
            }}
            h='100%'
            maxH={{ base: 'calc(100vh - 18px)', lg: 'calc(100vh - 98px)' }}
            maxW='100%'
            overflowY='scroll'
            className='noscrollbar'
        >
            <Box
                minH='100%'
                display={{ base: 'none', lg: 'block' }}
            >
                <BgTitleWired>
                    {data.fields.backgroundTitle}
                </BgTitleWired>
            </Box>
            <Box
                px={{ base: 0, lg: 0 }}
            >
                <Grid
                    gridTemplateColumns={{
                        base: '1fr 60px 1fr',
                        lg: '40% 20% 40%'

                    }}
                    gap='2'
                    maxW='900px'
                    mx='auto'
                >
                    <Heading
                        fontWeight='500'
                        fontFamily='Inknut Antiqua'
                        fontSize={{ base: '10px', lg: 'md' }}
                        textAlign='center'
                        pt={{ base: 14, lg: 24 }}

                        bgImage={pixelGray}
                        bgPos='center'
                        bgRepeat='repeat-y'
                    >
                        Expertise
                    </Heading>
                    <Heading
                        color='brand.blue'
                        fontWeight='500'
                        fontFamily='Inknut Antiqua'
                        fontSize={{ base: '10px', lg: 'md' }}
                        textAlign='center'
                        pt={{ base: 10, lg: 16 }}

                        bgImage={pixelGray}
                        bgPos='center'
                        bgRepeat='repeat-y'
                    >
                        Data-fueled consulting
                    </Heading>
                    <Heading
                        fontWeight='500'
                        fontFamily='Inknut Antiqua'
                        fontSize={{ base: '10px', lg: 'md' }}
                        textAlign='center'
                        pt={{ base: 14, lg: 24 }}

                        bgImage={pixelGray}
                        bgPos='center'
                        bgRepeat='repeat-y'
                    >
                        Actions
                    </Heading>
                </Grid>
                {data.fields.dataPrimary.primaryContent.items.map((item, index) =>
                    <Line
                        key={`technologyLine-${index}`}
                        col1={
                            <Rect>
                                {item.col1.map((line, index) =>
                                    <React.Fragment key={`col1-line-${index}`}>
                                        <Text>{line}</Text>
                                        {index + 1 !== item.col1.length ?
                                            <Point color='brand.blue' w={{ base: '3px', lg: '5px' }} />
                                            : null}
                                    </React.Fragment>
                                )}
                            </Rect>
                        }
                        col2={
                            <Circle
                                isLast={index - 1 === data.fields.dataPrimary.primaryContent.items.length}
                            >
                                {item.col2}
                            </Circle>
                        }
                        col3={
                            <Rect>
                                {item.col3.map((line, index) =>
                                    <React.Fragment key={`col3-line-${index}`}>
                                        <Text>{line}</Text>
                                        {index + 1 !== item.col3.length ?
                                            <Point color='brand.blue' w={{ base: '3px', lg: '5px' }} /> : null}
                                    </React.Fragment>
                                )}
                            </Rect>
                        }
                        isLast={index + 1 === data.fields.dataPrimary.primaryContent.items.length}
                    />)}
            </Box>
        </Grid>
    )


    return (
        <Box
            position='relative'
        >
            <Triptych
                col1={btnPrev}
                col2={<Body />}
                col3={null}
                logo={data.fields.displayLogo}
                handleGoHome={handleGoHome}
            />
        </Box>
    )
}

export default React.memo(Technology)