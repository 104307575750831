
import { Box, Center, Flex, Grid, Heading, Stack } from '@chakra-ui/layout';
import React, { useState } from 'react';
import Triptych from '../Layouts/Triptych';
import ScrollContainer from '../Components/ScrollerContainer';
import { ScrollingBlockLarge, ScrollingIntro } from '../Components/ScrollingBlock';
import WhatWeDoSchema from '../Components/WhatWeDoSchema';

const Scroller = (props) => {
    const [visibleBlock, setVisibleBlock] = useState(0);

    return (
        <>
            <ScrollContainer>
                <Stack
                    spacing='500px'
                    pr='35%'

                >
                    <ScrollingIntro
                        setVisibleBlock={() => setVisibleBlock(0)}
                        data={props.data.fields.dataPrimary.firstScreen}
                    />
                    {props.data.fields.dataPrimary.followingScreens.map((item, index) =>
                        <ScrollingBlockLarge
                            key={`scrollingBlockDesktop-${index}`}
                            id={`scrollingBlock-${index}`}
                            items={item.items}
                            body={item.body}
                            scrollIndicator={index + 1 < props.data.fields.dataPrimary.followingScreens.length}
                            setVisibleBlock={() => {
                                setVisibleBlock(index + 1);
                                console.log('go to : ', index)
                            }}
                            isLast={index + 1 === props.data.fields.dataPrimary.followingScreens.length}
                        />

                    )}
                </Stack>
            </ScrollContainer>
            <Box
                position='absolute'
                top='0'
                right='0'
                w='50%'
                pointerEvents='none'
            >
                <Center h='calc( 100vh - 120px )'>
                    <Box>
                        <WhatWeDoSchema visibleBlock={visibleBlock} />
                    </Box>
                </Center>
            </Box>
        </>
    );
};

const WhatWeDo = ({
    btnPrev,
    btnNext,
    data,
    handleGoHome
}) => {

    const Body = () => (

        <Grid
            templateColumns={{
                base: '100%',
                lg: '10% 90%'
            }}
            h='100%'
        >
            <Box
                minH='100%'
            >
                <Flex minH='100%' alignItems='center' pl={12}>
                    <Heading
                        color='transparent'
                        opacity='.50'
                        textTransform='uppercase'

                        style={{
                            "WebkitTextStroke": '.25px',
                            "WebkitTextStrokeColor": '#FFF'
                        }}

                        fontWeight='normal'
                        fontSize={{ base: '6xl', lg: '9xl' }}
                        fontFamily='Inknut Antiqua'
                        zIndex='base'
                    >
                        {data.fields.backgroundTitle}
                    </Heading>
                </Flex>
            </Box>
            <Box
                position='relative'
            >
                <Scroller data={data} />
            </Box>
        </Grid>

    )


    return (
        <Box
            position='relative'
        >
            <Triptych
                col1={btnPrev}
                col2={<Body />}
                // col3={btnNext}
                col3={btnNext}

                logo={data.fields.displayLogo}
                colorScheme={data.fields.colorScheme}

                handleGoHome={handleGoHome}
            />
        </Box>
    )
}

export default React.memo(WhatWeDo)