import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import {
    Heading,
    // List,
    // ListItem,
    Stack,
    Text
} from '@chakra-ui/react'

const options = {
    renderMark: {
        [MARKS.BOLD]: text => <strong>{text}</strong>,
    },
    renderNode: {
        [BLOCKS.HEADING_2]: (node, children) => <Heading fontSize='2xl' mb='-40px' style={{marginBottom:'-20px'}} fontWeight='normal'>{children}</Heading>,
        [BLOCKS.HEADING_3]: (node, children) => <Heading fontSize='xl' mb='-4' fontWeight='500'>{children}</Heading>,
        [BLOCKS.HEADING_4]: (node, children) => <Heading fontSize='xl' fontFamily='Inknut Antika' mb='-4' fontWeight='normal'>{children}</Heading>,
        // [BLOCKS.UL_LIST]: (node, children) => <List listStylePosition='outside'>{children}</List>,
        // [BLOCKS.LIST_ITEM]: (node, children) => <ListItem ml={4}>{children}</ListItem>,
        [BLOCKS.PARAGRAPH]: (node, children) => <Text fontSize="medium">{children}</Text>,
        // [BLOCKS.EMBEDDED_ASSET]: (node, children) => {

        //     const assetObject = getReferenceObject( node.data.target.sys.id );

        //     // console.log( 'EMBEDDED_ASSET', node, getReferenceObject( node.data.target.sys.id ) )


        //     return(
        //         <>
        //             <GatsbyImage
        //                 image={assetObject.gatsbyImageData}
        //                 alt={ assetObject.title }
        //             />
        //         </>
        //     )

        // },
        // [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        //     const slug = convertIdToSlug(node.data.target.sys.id, data.references)
        //     console.log('slug', slug)
        //     // const { title, description, slug } = node.data.target.fields;
        //     // console.log('node', node.data.target)
        //     // console.log('children', children)
        //     return <Link as={GatsbyLink} color='blue.900' textDecoration='underline' to={slug}>{children}</Link>
        // },
        // [INLINES.EMBEDDED_ENTRY]: (node, children) => {
        //     const slug = convertIdToSlug(node.data.target.sys.id, data.references)
        //     // const { title, description, slug } = node.data.target.fields;
        //     // console.log('node', node)
        //     // console.log('children', children)
        //     return <Link as={GatsbyLink} color='blue.900' textDecoration='underline' to={`${slug}`}>{children} coucou child</Link>
        // },
        // [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
        //     const entry = getReferenceObject(node.data.target.sys.id)
        //     // const { title, description, slug } = node.data.target.fields;
        //     return (
        //         <ProductCard
        //             key={entry.contentful_id}
        //             productId={entry.contentful_id}
        //             backendDocumentId={entry.backendDocumentId}
        //             title={entry.title}
        //             price={entry.price}
        //             level={entry.level}
        //             rating={entry.rating}
        //             editor={entry.editor}
        //             pictures={entry.pictures}
        //             // intro={<RichContent data={product.fields.intro} />}


        //             //Actions
        //             // onOpen={() => console.log('open')}
        //                 onOpen={() => navigate(entry.slug) }
        //         />
        //     )
        // },
        // [INLINES.HYPERLINK]: (node, children) => {
        //     console.log('node', node)
        //     return <Link as={GatsbyLink} color='blue.700' borderBottom='solid 1px' borderColor='blue.300' as='a' to={node.data.uri}>{children}</Link>
        // },
    },
}

const RichContent = ({ data }) => (
    <Stack spacing={ 6 } className="wysiwyg" fontWeight='400' fontSize='normal'>
        { documentToReactComponents(data, options) }
    </Stack>
)

export default RichContent