import { Box, Flex, Grid, VStack } from '@chakra-ui/layout'
import React from 'react'
import Profile from '../Components/Profile';
import Triptych from '../Layouts/Triptych';
import BgTitleWired from '../Components/BgTitleWired';

const Partners = ({
    btnPrev,
    btnNext,
    data,
    handleGoHome
}) => {

    const Body = () => (
        <Grid
            templateColumns={{
                base: '100%',
                lg: '10% 70% 20%'
            }}
            h='100%'
            maxH={{ base: 'calc(100vh - 20px)', lg: 'calc(100vh - 98px)' }}
            overflowY='scroll'
            className='noscrollbar'
        >
            <Box
                borderRight='solid 1px'
                borderRightColor='gray.100'
                minH='100%'
            >
                <BgTitleWired>
                    {data.fields.backgroundTitle}
                </BgTitleWired>
            </Box>
            <Box
                borderRight='solid 1px'
                borderRightColor={{ base: 'transparent', lg: 'gray.100' }}
                py={{ base: 0, lg: 20 }}
            >
                <VStack
                    spacing={{ base: 28, lg: 20 }}
                    pt={32}
                    px={{ base: 6, lg: 0 }}
                >
                    {data.fields.reference.map((item, index) =>
                        <Profile data={item} even={index % 2 !== 0} key={item.sys.id} />
                    )}
                </VStack>
            </Box>
            <Box
                display={{ base: 'none', lg: 'initial' }}
            >
                <Flex minH='100%' alignItems='center' pl='30px'>
                    <Box
                        bgColor='brand.blue'
                        maxW='300px'
                    >
                    </Box>
                </Flex>
            </Box>
        </Grid>
    )


    return (
        <Box
            position='relative'
        >
            <Triptych
                col1={btnPrev}
                col2={<Body />}
                col3={btnNext}
                logo={data.fields.displayLogo}
                handleGoHome={handleGoHome}
            />
        </Box>
    )
}

export default React.memo(Partners)