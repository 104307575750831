import { AspectRatio, Box, Heading, Grid, HStack, Link, Stack, Text, Image } from '@chakra-ui/react'
import React from 'react'
import { Circle, NextIcon } from '../Icons/Customs'
import { SocialNetworkButton } from './ContactButtons'
const Profile = ({ data, even }) => {
    return (
        <Box>
            <Grid
                gridTemplateColumns={{
                    base: '100%',
                    lg: !even ? '40% 60%' : '60% 40%'
                }}
                w={{ base: '100%', lg: '100%' }}
                gap={{ base: 0, lg: 12 }}
                position='relative'
                zIndex='banner'
            >
                <Box
                    order={{ base: 0, lg: even ? 2 : 0 }}
                    mb={{ base: 10, lg: 0 }}
                >
                    {data.fields.primaryImage && data.fields.primaryImage.fields.file ?

                        <AspectRatio
                            maxW="560px"
                            ratio={1 / 1}
                            bgColor='brand.blue'
                            transform='translate3d( 20px, 0px, 0 )'

                        >
                            <Image
                                fit='cover'
                                transform='translate3d( -20px, -20px, 0 )'
                                src={
                                    data.fields.primaryImage.fields.file.url + '?w=840&fm=webp&q=50'
                                }
                                alt={data.fields.title}
                            />
                        </AspectRatio>
                        : null}

                </Box>
                <Stack
                    spacing='6'
                    pl={{ base: 0, lg: !even ? 10 : 0 }}
                    pr={{ base: 0, lg: even ? 10 : 0 }}
                    pt={{ base: 0, lg: 6 }}
                >
                    <Heading
                        fontFamily='Inknut Antiqua'
                        fontSize='3xl'
                        fontWeight='light'
                        color='brand.blue'
                        textTransform='uppercase'
                    >{data.fields.title}</Heading>
                    <HStack
                        textTransform='uppercase'
                        fontWeight='bold'
                        fontSize={{ base: '8px', lg: '10px' }}
                    >
                        {
                            data.fields
                            && data.fields.skills.map((item, index) =>
                                <React.Fragment key={`${item}-${index}`}>
                                    <Text letterSpacing='2px'>
                                        {item}
                                    </Text>
                                    {index + 1 < data.fields.skills.length ?
                                        <Circle w='5px' color='brand.blue' />
                                        : null}
                                </React.Fragment>
                            )
                        }
                    </HStack>

                    <Box fontSize='md'>{data.fields.intro}</Box>

                    {data.fields.externalUrl
                        && data.fields.externalUrl !== ''
                        && data.fields.externalUrlLabel
                        && data.fields.externalUrlLabel !== ''
                        ?
                        <SocialNetworkButton
                            label={data.fields.externalUrlLabel}
                            href={data.fields.externalUrl}
                        />
                        : null}
                </Stack>
            </Grid>
        </Box>
    )
}

export default Profile