import { Box, Image } from '@chakra-ui/react';
import React from 'react';
import logoBlue from '../images/logo-blue.svg';
import logoWhite from '../images/logo-white.svg';

import { scroller } from 'react-scroll';

const Logo = (props) => (

    <Box
        onClick={() => console.log('clickkk')}
        role='button'
    >
        <Image
            // {...props}
            src={props.colorScheme === 'blue' ? logoWhite : logoBlue}
            alt='Going Public'
            position='absolute'
            zIndex='toast'
            top={{ base: '15px', lg: '20px' }}
            left={{ base: '15px', lg: '20px' }}
            zIndex='banner'
            w={{ base: '50px', lg: '120px' }}
            opacity={props.isVisible ? 1 : 0}

            // onClick={() => props.handleGoHome()}

            onClick={() => {

                scroller.scrollTo('swiper-7', {
                    horizontal: true,
                    duration: 1500,
                    delay: 100,
                    smooth: true,
                    containerId: 'container'
                })

            }}
        />
    </Box>
)

export default Logo