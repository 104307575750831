import { Image } from '@chakra-ui/image';
import { Box, Flex, Grid, HStack, Stack, Text, VStack } from '@chakra-ui/react'
import React, { useState } from 'react'
import Title from '../Components/Title';
import { Circle } from '../Icons/Customs';
import Triptych from '../Layouts/Triptych';
import RichContent from '../Components/RichContent'
import { AppointmentButton, SocialNetworkButton } from '../Components/ContactButtons';
import BgTitleWired from '../Components/BgTitleWired';
import { PopupButton } from '@typeform/embed-react';

// const Portrait = React.memo(props => {

const Portrait = ({
    btnPrev,
    btnNext,
    data,
    handleGoHome,
    isCurrent
}) => {

    // const {
    //     btnPrev,
    //     btnNext,
    //     data,
    //     handleGoHome,
    //     isCurrent
    // } = props;

    const Body = () => (
        <Grid
            templateColumns={{
                base: '100%',
                lg: data.fields.dataPrimary.reverseDisplay ? '20% 40% 40%' : '20% 40% 40%'
            }}
            h='100%'
            maxH={{ base: 'calc(100vh - 18px)', lg: 'calc(100vh - 98px)' }}
            overflowY='scroll'
            className='noscrollbar'
            maxW='1250px'
            mx='auto'
        >
            <Box
                borderRight='solid 1px'
                borderRightColor='gray.100'
                minH='100%'
            >
                <BgTitleWired>
                    {data.fields.backgroundTitle}
                </BgTitleWired>
            </Box>
            <Box
                borderRight={!data.fields.dataPrimary.reverseDisplay ? 'solid 1px' : 'none'}
                borderLeft={data.fields.dataPrimary.reverseDisplay ? 'solid 1px' : 'none'}
                borderRightColor='gray.100'
                borderLeftColor='gray.100'
                order={{ base: 1, lg: data.fields.dataPrimary.reverseDisplay ? 2 : 0 }}
                pb={{ base: 32, lg: 32 }}
            >
                <Flex
                    minH='100%'
                    // alignItems='center'
                    position='relative'
                    zIndex='banner'
                >
                    <Stack
                        spacing={6}
                        mt={12}
                        px={{ base: 6, lg: 0 }}
                        pt={{ base: 0, lg: 32 }}
                    >
                        <Box
                            pr={{ base: 0, lg: 24 }}
                        >
                            <Title>
                                {data.fields.title}
                            </Title>
                        </Box>
                        <Box>
                            <HStack
                                textTransform='uppercase'
                                fontWeight='bold'
                                fontSize={{ base: '7px', lg: '10px' }}
                            >
                                {
                                    data.fields.dataPrimary
                                    && data.fields.dataPrimary.skills
                                    && data.fields.dataPrimary.skills.map((item, index) =>
                                        <React.Fragment key={`${item}-${index}`}>
                                            <Text letterSpacing='1px'>
                                                {item}
                                            </Text>
                                            {index + 1 < data.fields.dataPrimary.skills.length ?
                                                <Circle w='5px' color='brand.blue' />
                                                : null}
                                        </React.Fragment>
                                    )
                                }
                            </HStack>
                        </Box>
                        <Box pr={{ base: 0, lg: 24 }}>
                            <Box
                                fontSize={{ base: 'sm', lg: 'sm' }}
                            >
                                <RichContent data={data.fields.contentPrimary} />
                            </Box>
                        </Box>
                        <VStack
                            alignItems='flex-start'
                            spacing={8}
                            pt={{ base: 8, lg: 18 }}
                        >
                            {
                                data.fields.dataPrimary.externalLink
                                    && data.fields.dataPrimary.externalLink.url
                                    && data.fields.dataPrimary.externalLink.label
                                    ?
                                    <Box>
                                        <SocialNetworkButton
                                            label={data.fields.dataPrimary.externalLink.label}
                                            href={data.fields.dataPrimary.externalLink.url}
                                        />
                                    </Box>
                                    : null}
                            {data.fields.dataPrimary.tallyFormID ?
                                <Box>
                                    <AppointmentButton
                                        data-tally-open={data.fields.dataPrimary.tallyFormID} data-tally-layout="modal" data-tally-hide-title="1"
                                    />
                                </Box>
                                : null}
                        </VStack>
                    </Stack>
                </Flex>
            </Box>
            <Box
                px={{ base: 6, lg: 0 }}
                pt={{ base: 32 }}
                pr={{ base: 0, lg: 10 }}
                mt={{ base: 0, lg: 12 }}

                position='relative'
                zIndex='banner'
            >
                <Flex
                    minH='100%'
                    alignItems={{ base: 'flex-start' }}
                    pl={{ base: '20px', lg: '30px' }}>
                    <Box
                        bgColor='brand.blue'
                        maxW={{ base: '220px', lg: '360px' }}
                        transform={{ base: 'translate3d( 0, 0, 0)', lg: 'translate3d( 0px, 30px, 0)' }}

                    >

                        {
                            data.fields.primaryImage
                                && data.fields.primaryImage.fields
                                && data.fields.primaryImage.fields.file ?

                                <Image
                                    src={
                                        `${data.fields.primaryImage.fields.file.url}?w=1360&h=1600&fit=fill&fm=webp&q=60`
                                    }
                                    transform={{ base: 'translate3d( -20px, -20px, 0)', lg: 'translate3d( -30px, -30px, 0)' }}
                                    loading='lazy'
                                    // maxW='100%'
                                    w='140%'
                                />
                                : null}
                    </Box>
                </Flex>
            </Box>
        </Grid>
    )
    return (
        <Box
            position='relative'
        >
            <Triptych
                col1={btnPrev}
                col2={<Body />}
                col3={btnNext}
                logo={data.fields.displayLogo}
                handleGoHome={handleGoHome}
            />
        </Box>
    )
}

export default React.memo(Portrait)